<template>
  <v-footer id="home-footer" color="secondary" dark min-height="60">
    <v-container fluid>
      <v-row>
        <v-col class="text-center py-0" cols="12">
          Copyright &copy; {{ new Date().getFullYear() }} -
          <a class="white--text" href="https://foodeo.es">Foodeo</a> 🧡
        </v-col>
      </v-row>
      <v-row justify="center">
        <a @click="navigateTo('/legal-concern')" class="white--text">{{
          $t("legalConcernTitle")
        }}</a>
        <v-divider class="mx-1" vertical></v-divider>
        <a @click="navigateTo('/cookie-policy')" class="white--text">{{
          $t("cookiePolicyTitle")
        }}</a>
        <v-divider class="mx-1" vertical></v-divider>
        <a @click="navigateTo('/privacy-policy')" class="white--text">{{
          $t("privacyPolicyTitle")
        }}</a>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  // data: () => ({
  //   social: [
  // {
  //   app: "LinkedIn",
  //   externalUrl: "https://test.com",
  //   to: null,
  // },
  // {
  //   app: "Twitter",
  //   externalUrl: "https://test.com",
  //   to: null,
  // },
  // {
  //   app: "Preguntas Frecuentes",
  //   externalUrl: null,
  //   to: "/preguntas-frecuentes",
  // },
  // {
  //   app: "Politicas",
  //   externalUrl: `${process.env.VUE_APP_API}/current-terms`,
  //   to: null,
  // },
  //   ],
  // }),
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="sass">
#home-footer
  background-position: center bottom
  font-size: 12px
  line-height: 20px

  a
    text-decoration: none
</style>
